import React from "react"
import Container from "../components/Container/Container";
import styles from "../styles/pages/survey.module.scss";
import PageHeader from "../components/PageHeader/PageHeader";

const example = () => {

  return <Container>
    <PageHeader header={"Badanie: Preferencje pacjentów dotyczące leczenia ogólnego w AZS"}/>
    <div className={styles.contentWrapper}>
      <div>
        <p className={styles.postHeader}/>
        <div className={styles.postContent}>
          <h3>Wszystkich <span>dorosłych</span> pacjentów chorujących na AZS zapraszamy do wypełnienia krótkiej ankiety
            dotyczącej leczenia ogólnego.</h3>
          <div className={styles.url}>
            <a
              href={"https://docs.google.com/forms/d/e/1FAIpQLSd3ovxankvgtGIFf4eXh2lxZ8ZP8uf7Zg5anPNIynspSV1WNg/viewform?fbclid=IwAR3AWpfMdrhLlvRzYxEtKBYHXtj3M_S4B13QTM0F_tLqRJPBVZfmkqaBlGs"}
              target={"_blank"}>
              Ankieta
            </a>
          </div>
          <h3 className={styles.custom}>Jej wyniki pozwolą w przyszłości ułatwić podejmowanie decyzji dotyczących leczenia systemowego AZS,
            zarówno lekarzom, jak i pacjentom.</h3>
        </div>
      </div>
    </div>
  </Container>;
}

export default example
